
import Alert from '@/components/Alert';
import SiteFooter from "@/components/SiteFooter";
import validationConstants from '@/constants/validation.constants';
import { backendExceptionHandler } from '@/mixins';
import AuthService from "@/service/AuthService";
import LabeledTextField from '../components/LabeledTextField.vue';

    const INTRO_VIDEO = "https://www.youtube.com/embed/sPQWWKt29ZM";
    const { emailRegex } = validationConstants;
    export default {
        name: "Login",
        mixins: [backendExceptionHandler],
        layout: 'guest',
        components: {
            Alert,
            SiteFooter,
            LabeledTextField
        },

        head() {
            return {
                title: 'MoneyPeak - Your Financial Success, Simplified',
                meta: [{
                    title: 'Anmelden bei MoneyPeak Finanzplattform'
                }, {
                    name: 'description',
                    content: 'Die Finanzplattform MoneyPeak trackt und analysiert Investitionen und Portfolios. Vom Wertpapier-Depot bis zum Crypto-Kauf. Nützlich, simpel und kostenlos',
                }],
                link: [{
                    rel: 'preload', href: require('@/assets/desktop.webp'), as: 'image',
                }]
            }
        },

        data() {
            return {
                email: "",
                password: "",
                isTwoFa: false,
                twoFaCode: null,
                errorMessage: "",
                isLoading: false,
                signupRoute: 'signup',
                autofillFix: false,
                infoMessage: undefined,
                resetPasswordState: {
                    menu: false,
                    message: null,
                    isLoading: false,
                    email: null,
                    errorMessage: null
                },
                firstInfo: {
                    title: this.$t('pages.index.texts.title1'),
                    content: this.$t('pages.index.texts.content1'),
                    imageSrc: require('@/assets/landing_page/landing_page_image_1.png'),
                    imageSrcDark: require('@/assets/landing_page/landing_page_image_1_dark.png'),
                    width: 564,
                    height: 376,
                    imgOverflow: true,
                    alt: this.$t('pages.index.texts.alt1'),
                },
                texts: [],
                logos: [],
                knowMore: false,
                slideGroup: null,
                introVideo: INTRO_VIDEO,
                credential: {},
                validResetPasswordForm: false
            }
        },

        computed: {
            isLoggedIn() {
                return this.$store.state.account.user
            },
            logoPath() {
                return this.$vuetify.theme.dark ?
                    require('@/assets/money-peak-full-logos/money-peak-full-white-logo.png') :
                    require('@/assets/money-peak-full-logos/money-peak-full-black-logo.png');
            },
            isDarkMode() {
                return this.$vuetify.theme.dark
            },
            rules() {
                return {
                    required: value => !!value || this.$t('common.validation.required'),
                    isEmail: value => {
                        if(!emailRegex.test(value)) {
                            return this.$t('pages.index.resetPassword.invalidEmail')
                        }
                        return true
                    }
                }
            },
        },

        methods: {
            async verify() {
                this.isLoading = true
                this.errorMessage = null
                this.infoMessage = null
                try {
                    const response = await AuthService.verify({
                        email: this.email,
                        password: this.password
                    }, this.$gtag, this.$t)
                    this.$store.commit('account/setCredential', response)
                    this.$store.commit('account/setIsTwoFaEnabled', false)
                } catch(error) {
                    if (error.message === 'auth/multi-factor-auth-required') {
                        this.$store.commit('account/setIsTwoFaEnabled', true)
                        this.infoMessage = this.$t('multifactorAuthentication')
                        this.isTwoFa = true
                    } else {
                        this.errorMessage = 'Login fehlgeschlagen'
                    }
                    return
                } finally {
                    this.isLoading = false
                    this.clearInput()
                }
            },
            async verifyTwoFa() {
                this.isLoading = true
                try {
                    await AuthService.completeTwoFaLogin(this.twoFaCode, this.$gtag)
                } catch (error) {
                    if (error.message === 'auth/invalid-verification-code') {
                        this.errorMessage = 'Der eingegebene Code ist falsch.'
                    } else {
                        this.errorMessage = error.message
                    }
                    this.isLoading = false
                    return
                }
                this.isLoading = false
            },
            async googleSignin() {
                AuthService.googleSignin(this.$gtag).then((response) => {
                    this.$store.commit('account/setCredential', response)
                    this.$store.commit('account/setIsTwoFaEnabled', false)
                }).catch(e => {
                    if (e.message === 'auth/multi-factor-auth-required') {
                        this.$store.commit('account/setIsTwoFaEnabled', true)
                        this.infoMessage = this.$t('multifactorAuthentication')
                        this.isTwoFa = true
                    } else {
                        // e.g. e.code === 'auth/account-exists-with-different-credential'
                        this.errorMessage = `Wir konnten Dich nicht einloggen: ${e.code}`
                        console.log(e)
                        return
                    }
                });

            },

            async facebookSignin() {
                AuthService.facebookSignin().then((response) => {
                    this.$store.commit('account/setCredential', response)
                    this.$store.commit('account/setIsTwoFaEnabled', false)
                }).catch(e => {
                    if (e.message === 'auth/multi-factor-auth-required') {
                        this.$store.commit('account/setIsTwoFaEnabled', true)
                        this.infoMessage = this.$t('multifactorAuthentication')
                        this.isTwoFa = true
                    } else {
                        // e.g. e.code === 'auth/account-exists-with-different-credential'
                        this.errorMessage = `Wir konnten Dich nicht einloggen: ${e.code}`
                        console.log(e)
                        return
                    }
                });
            },

            async resetPassword() {
                this.$refs.resetPasswordForm.validate()
                if (!this.validResetPasswordForm) {
                    return
                }
                this.resetPasswordState.isLoading = true
                try {
                    const url = location.origin + '/login'
                    await AuthService.resetPassword(this.resetPasswordState.email, url)
                    this.resetPasswordState.errorMessage = null
                    this.resetPasswordState.message = this.$t('pages.index.resetPassword.successMessage')
                } catch (error) {                    
                    if (error.code === 'auth/invalid-email') {
                        this.resetPasswordState.errorMessage = this.$t('pages.index.resetPassword.invalidEmail')
                    } else if (error.code === 'auth/user-not-found') {
                        this.resetPasswordState.errorMessage = this.$t('pages.index.resetPassword.emailNotFound')
                    } else {
                        this.resetPasswordState.errorMessage = this.$t('common.errors.generalError')
                    }
                } finally {
                    this.resetPasswordState.isLoading = false
                }
            },

            closeResetPasswordDialog(doClose = false) {
                if (doClose) {
                    this.resetPasswordState.menu = false
                }
                this.$nextTick(() => {
                    this.resetPasswordState.message = null
                    this.resetPasswordState.email = null
                })
            },

            clearInput() {
                this.password = ''
            },

            getTexts() {
                return [{
                    title: this.$t('pages.index.texts.title2'),
                    content: this.$t('pages.index.texts.content2'),
                    imageSrc: require('@/assets/landing_page/landing_page_image_2.png'),
                    imageSrcDark: require('@/assets/landing_page/landing_page_image_2_dark.png'),
                    width: 564,
                    height: 376,
                }, {
                    title: this.$t('pages.index.texts.title3'),
                    content: this.$t('pages.index.texts.content3'),
                    imageSrc: require('@/assets/landing_page/landing_page_image_3.png'),
                    imageSrcDark: require('@/assets/landing_page/landing_page_image_3_dark.png'),
                    width: 564,
                    height: 376,
                    knowMore: [
                        this.$t('pages.index.texts.knowMore1'),
                        this.$t('pages.index.texts.knowMore2'),
                        this.$t('pages.index.texts.knowMore3'),
                        this.$t('pages.index.texts.knowMore4'),
                        this.$t('pages.index.texts.knowMore5'),
                        this.$t('pages.index.texts.knowMore6'),
                    ],
                }, {
                    title: this.$t('pages.index.texts.title4'),
                    content: this.$t('pages.index.texts.content4'),
                    imageSrc: require('@/assets/landing_page/landing_page_image_4.png'),
                    imageSrcDark: require('@/assets/landing_page/landing_page_image_4_dark.png'),
                    width: 564,
                    height: 376,
                }]
            },

            autoLoginCheckingInterface() {
                let intervalDetectAutofill = setInterval(() => {
                    if (document.querySelectorAll('input[type="password"]:-webkit-autofill').length > 0) {
                        this.autofillFix = true
                        this.$refs.form.$children[0].$refs.label.classList.add('v-label--active')
                        this.$refs.form.$children[0].$refs['input-slot'].children[0].children[0].style = 'width: 35.25px'
                        this.$refs.form.$children[1].$refs.label.classList.add('v-label--active')
                        this.$refs.form.$children[1].$refs['input-slot'].children[0].children[0].style = 'width: 59.25px'
                        clearInterval(intervalDetectAutofill)
                    }
                }, 100)
                // if after 1s nothing appear, means no autofill was made
                setTimeout(() => {
                    if (intervalDetectAutofill) {
                        clearInterval(intervalDetectAutofill)
                        intervalDetectAutofill = null
                    }
                }, 1000)
            },

            updateErrorMessage() {
               this.errorMessage = ""
               this.infoMessage = ""
            },

            importAll(r) {
                const imgs = {}
                r.keys().forEach(key => (imgs[key] = r(key)))
                this.logos = imgs
            },

            advanceSlideShow() {
                this.slideGroup = Math.max((this.slideGroup - this.slideGroupOffset + 1)
                    % (Object.keys(this.logos).length - this.slideGroupOffset) + this.slideGroupOffset, Math.ceil(this.slideGroupOffset / 2))

                if (this.slideGroup == Object.keys(this.logos).length - 1) {
                    clearInterval(this.interval)
                    setTimeout(() => {
                        this.slideGroup = 0
                        this.startSlideShow()
                    }, 3000)
                }
            },

            startSlideShow() {
                this.slideGroupOffset = Math.ceil(this.$refs.slideGroup?.$el.clientWidth / 114) - 1  // dividing by the slide-group-item plus margins (90 + 24)
                this.slideGroup = 0
                this.interval = setInterval(this.advanceSlideShow, 1000)
            },

            redirectIfLoggedIn() {
                if (!this.isLoggedIn) {
                    return
                }
                const dashboardPageRouteName = this.$getTranslatedRoutePath('/finance/dashboard/')
                const routeOptions = {
                    path: this.$route.params.redirectTo || dashboardPageRouteName,
                }                
                this.$router.push(routeOptions)
            },

            handleLoginRejected(message) {
                this.errorMessage = message
            },
        },

        created() {
            this.texts = this.getTexts()
            this.infoMessage = this.$route.params.redirectMessage
            this.$root.$on('login-rejected', this.handleLoginRejected)
            this.importAll(require.context('@/assets/broker_logos'))
        },

        beforeMount() {
            this.redirectIfLoggedIn()
        },

        beforeDestroy() {
            clearInterval(this.interval)
            this.$root.$off('login-rejected')
        },

        watch: {
            isLoggedIn(isLoggedIn) {
                this.redirectIfLoggedIn()
            }
        },

        mounted() {
            this.autoLoginCheckingInterface()
            this.startSlideShow()
        }
    }
